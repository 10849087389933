import { PlayClassroomDoc } from 'src/lib/1/schema';
import { FirebaseManager } from 'src/lib/2/firebase-manager';
import useSWR from 'swr';

const firebaseManager = FirebaseManager.getInstance();
const playClassroomPath = 'playClassroom';

const getClassrooms = async (ids: string[]) => {
  const promises = ids.map((id) =>
    firebaseManager.getDoc<PlayClassroomDoc>(`${playClassroomPath}/${id}`)
  );
  const classrooms = await Promise.all(promises);
  const filtered = (
    Array.isArray(classrooms) ? classrooms : [classrooms]
  )?.filter((classroom) => classroom && !classroom.hide);
  return filtered;
};

const useClassrooms = (ids: string[]) => {
  const { data: classrooms, isValidating: classroomsLoading } = useSWR(
    ids ? ids : null,
    getClassrooms
  );

  return {
    classrooms: (classrooms
      ? (Array.isArray(classrooms) ? classrooms : [classrooms])?.filter(
          (classroom) => classroom
        )
      : []) as PlayClassroomDoc[],
    classroomsLoading,
  };
};

export default useClassrooms;
