import { FC, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'src/stores/auth-context';
import classes from './SigninForm.module.scss';
import { getLocalStorageValue, setLocalStorageValue } from 'src/lib/1/util';
import { OnCourses } from 'src/lib/1/schema';

type SigninFormValues = {
  id: string;
  password: string;
};

const SigninForm: FC = () => {
  const nativate = useNavigate();
  const { course, setOnCourseList } = useAuth();
  const [wait, setWait] = useState(false);

  const onFinish = (values: SigninFormValues) => {
    if (!values.id || !values.password) {
      notification.error({
        message: '아이디와 비밀번호를 입력해주세요.',
      });
      return;
    }

    const onCourses = getLocalStorageValue<OnCourses>('course');
    const newCourses = {
      ...onCourses,
      [values.id]: values.password,
    };
    const availableList =
      course?.filter((c) => c.password === newCourses[c.id]) ?? [];
    setLocalStorageValue<OnCourses | null>(
      'course',
      availableList.length > 0
        ? Object.fromEntries(availableList.map((i) => [i.id, i.password]))
        : null
    );
    setOnCourseList(availableList.map((i) => i._id));
    if (availableList.map((i) => i._id).length === 0) {
      notification.error({
        message: '아이디와 비밀번호를 확인해주세요.',
      });
      setWait(true);
      setTimeout(() => {
        setWait(false);
      }, 2000);
      return;
    }
    nativate('/');
  };

  return (
    <Form
      name="normal_login"
      className={classes['login-form']}
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="id"
        rules={[{ required: true, message: 'Please input your id' }]}
      >
        <Input
          size="large"
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="id"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          size="large"
          prefix={<LockOutlined className={classes['site-form-item-icon']} />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      {/* <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
      </Form.Item> */}

      <Form.Item>
        <Button
          disabled={!course}
          loading={wait}
          size="large"
          type="primary"
          htmlType="submit"
          className={classes['login-form-button']}
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SigninForm;
