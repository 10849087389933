import { FC } from 'react';

import SigninForm from './SigninForm/SigninForm';
import classes from './Signin.module.scss';

import SmallTalk from '../../assets/images/people-small-talk.png';
import TitleLogo from '../../assets/images/play-nny.svg';

const Signin: FC = () => {
  return (
    <div className={classes.signinContainer}>
      <header>
        <img alt="title-logo" src={TitleLogo}/>
      </header>
      <section>
      <img
        className={classes.signinImage}
        alt="people-small-talk"
        src={SmallTalk}
      />
      <div className={classes.imageTitle}>
        누구나
        <span className={classes.rotatingContainer}>
          하게되는
          <span className={classes.rotatingText}>하고싶은</span>
          <span className={classes.rotatingText}>알게되는</span>
          <span className={classes.rotatingText}>하게되는</span>
        </span>
        영어!
      </div>
      <p className={classes.authContent}>수년간, 수만 명의 수강생들에게 증명된 영어 말하기 종합 프로그램</p>

      <SigninForm />
      </section>

      <footer>
        <p>누나영 어학연구소</p>
      </footer>
    </div>
  );
};

export default Signin;
