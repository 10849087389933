import { useMemo } from 'react';
import { getPreviewToken } from 'src/lib/3/firebase-short-cut';
import useSWR from 'swr';

const usePreviewToken = (token: string) => {
  const { data: previewToken, isValidating: isTokenLoading } = useSWR(
    'previewToken',
    getPreviewToken
  );
  const isTokenValid = useMemo(() => {
    if (previewToken?.expiredAt === undefined) return false;
    const expiredAt = new Date(previewToken.expiredAt);
    const now = new Date();
    return expiredAt > now && previewToken?.token === token;
  }, [previewToken?.expiredAt, previewToken?.token, token]);

  return {
    isTokenValid,
    isTokenLoading,
  };
};

export default usePreviewToken;
