import { BrowserRouter } from 'react-router-dom';
import Router from './Router';

import './App.scss';
import { ConfigProvider } from 'antd';
import { AuthProvider } from './stores/auth-context';

const theme = {
  token: {
    colorPrimary: '#00bfa5',
  },
};

function App() {
  return (
    <ConfigProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
