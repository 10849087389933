import { FC } from 'react';
import Home from 'src/components/Home/Home';

import { useTitle } from '../hooks/useTitle';

const HomePage: FC = () => {
  useTitle('Plan NNY');
  return <Home />;
};

export default HomePage;
