import { RightOutlined } from '@ant-design/icons';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlayLectureDoc } from 'src/lib/1/schema';
import { subscribePlayLectureForClassrooom } from 'src/lib/3/firebase-short-cut';

import classes from './ClassroomItem.module.scss';

type ClassroomItemProps = {
  classroomId: string;
  name: string;
};

const ClassroomItem: FC<ClassroomItemProps> = ({ classroomId, name }) => {
  const navigate = useNavigate();
  const [lectures, setLectures] = useState<PlayLectureDoc[] | null>(null);

  const goToCourse = useCallback(() => {
    navigate(`/classroom/${classroomId}`);
  }, [classroomId, navigate]);

  useEffect(() => {
    if (classroomId) {
      const subscription =
        subscribePlayLectureForClassrooom(classroomId).subscribe(setLectures);
      return () => subscription.unsubscribe();
    }
  }, [classroomId]);

  return (
    <div className={classes.classroomCard} onClick={goToCourse}>
      <div className={classes.left}>
        <h3>{name}</h3>
        <p>강의: {lectures?.length}개</p>
      </div>
      <div className={classes.right}>
        <RightOutlined />
      </div>
    </div>
  );
};

export default ClassroomItem;
