import { FC, useMemo } from 'react';

import classes from './MovieCard.module.scss';
import { appendQueryParam } from 'src/lib/1/util';

type MovieCardProps = {
  embedUrl: string;
};

const MovieCard: FC<MovieCardProps> = ({ embedUrl }) => {
  const src = useMemo(() => {
    const url = `https://player.vimeo.com/video/${embedUrl}`;
    return appendQueryParam(url, 'quality', '720p');
  }, [embedUrl]);
  return (
    <div className={classes.movieContainer}>
      <iframe
        className={classes.respIframe}
        src={src}
        frameBorder="0"
        allowFullScreen
        title="nny-temp"
      />
    </div>
  );
};

export default MovieCard;
