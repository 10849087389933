import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import classes from './TopNavigationBar.module.scss';

interface TopNavigationBarProps {
  title?: string;
  leftButton?: boolean | JSX.Element;
  leftButtonAction?: () => void;
  rightButton?: JSX.Element;
  borderBottom?: boolean;
}

const TopNavigationBar: FC<TopNavigationBarProps> = ({
  title,
  leftButton,
  leftButtonAction,
  rightButton,
  borderBottom,
}) => {
  const navigate = useNavigate();

  const onClickLeftButton = leftButtonAction || (() => navigate(-1));

  return (
    <header
      className={`${classes.topNavigationBarContainer} ${
        borderBottom ? classes.borderBottom : ''
      }`}
    >
      {leftButton ? (
        typeof leftButton === 'boolean' ? (
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={onClickLeftButton}
            style={{ fontSize: 18, width: 60, padding: 0 }}
          />
        ) : (
          leftButton
        )
      ) : (
        <span className={classes.emptyBox} />
      )}
      <h3>{title}</h3>
      {rightButton ? rightButton : <span className={classes.emptyBox} />}
    </header>
  );
};

export default TopNavigationBar;
