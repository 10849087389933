import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { FC, useEffect, useState } from 'react';
import { OnCourses } from 'src/lib/1/schema';
import { getLocalStorageValue } from 'src/lib/1/util';
import { useAuth } from 'src/stores/auth-context';

const MenuDrawer: FC = () => {
  const { signOut } = useAuth();
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState<OnCourses | null>(null);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const course = getLocalStorageValue<OnCourses>('course');
    setCourses(course ?? null);
  }, [open]);

  return (
    <>
      <Button
        type="text"
        onClick={showDrawer}
        icon={<MenuOutlined />}
        style={{ width: 60 }}
      />
      <Drawer
        width="320px"
        title=""
        placement="right"
        onClose={onClose}
        open={open}
      >
        <p
          style={{
            borderBottom: '1px solid var(--gray200)',
            paddingBottom: 20,
          }}
        >
          Signed in as <b>{Object.keys(courses ?? []).join(',')}</b>
        </p>
        <Button onClick={signOut}>Sign out</Button>
      </Drawer>
    </>
  );
};

export default MenuDrawer;
