import { FC, useEffect } from 'react';
import useClassrooms from 'src/hooks/useClassrooms';
import useCourses from 'src/hooks/useCourses';
import { useAuth } from 'src/stores/auth-context';
import MenuDrawer from '../MenuDrawer/MenuDrawer';
import TopNavigationBar from '../TopNavigationBar/TopNavigationBar';
import ClassroomItem from './ClassroomItem/ClassroomItem';

import classes from './Home.module.scss';

const Home: FC = () => {
  const { onCourseList, setLoading } = useAuth();
  const { courses, coursesLoading } = useCourses(onCourseList ?? []);
  const classrooomList = (courses
    ?.map((course) => course?.classrooms.map((i) => i.classroomId))
    .filter((list) => list)
    .flat() ?? []) as string[];
  const { classrooms, classroomsLoading } = useClassrooms(classrooomList);

  useEffect(() => {
    setLoading(coursesLoading || classroomsLoading);
  }, [coursesLoading, classroomsLoading, setLoading]);

  useEffect(() => {}, []);
  return (
    <div>
      <TopNavigationBar
        title={courses ? courses.map((c) => c.name).join(', ') : ''}
        rightButton={<MenuDrawer />}
      />
      <div className={classes.classroomList}>
        {classrooms.map((classroom) => {
          return (
            <ClassroomItem
              classroomId={classroom?._id}
              name={classroom?.name}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Home;
