import { notification } from 'antd';
import { User } from 'firebase/auth';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { OnCourses, PlayCourseDoc } from 'src/lib/1/schema';
import { getLocalStorageValue, setLocalStorageValue } from 'src/lib/1/util';
import { FirebaseManager } from 'src/lib/2/firebase-manager';
import { subscribePlayCourse } from 'src/lib/3/firebase-short-cut';

interface AuthContent {
  isAuthenticated: boolean;
  course: PlayCourseDoc[] | null;
  loading: boolean;
  onCourseList: string[] | null;
  setLoading: (loading: boolean) => void;
  setOnCourseList: (onCourseList: string[] | null) => void;
  signOut: () => void;
}

const authContext = createContext<AuthContent | null>(null);
const firebaseManager = FirebaseManager.getInstance();

export const AuthProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(authContext);
  if (context == null) {
    throw new Error('Out of context: authProvider is not set');
  }
  return context;
};

const useProvideAuth = () => {
  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState<User | null | false>(null);
  const [course, setCourse] = useState<PlayCourseDoc[] | null>(null);
  const [onCourseList, setOnCourseList] = useState<string[] | null>(null);
  const [loading, setLoading] = useState(false);

  const singInForAuth = async () => {
    setLoading(true);
    try {
      await firebaseManager.signIn('3124dev@naver.com', 'nny3124');
    } catch (error) {
      console.error(error);
      notification.error({
        message: '네트워크 에러가 발생했습니다.',
        description: '네트워크 상태를 확인후 새로고침 해주세요.',
      });
    }
    setLoading(false);
  };

  const signOut = () => {
    setLocalStorageValue('course', null);
    setOnCourseList(null);
    navigate('/');
  };

  useEffect(() => {
    const subscription = firebaseManager
      .observeAuthState()
      .subscribe((rawUser) => {
        if (rawUser) {
          setAuthUser(rawUser);
        } else {
          setAuthUser(false);
        }
      });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    singInForAuth();
  }, []);

  useEffect(() => {
    if (authUser) {
      const subscription = subscribePlayCourse.subscribe(setCourse);
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [authUser]);

  // 최초 접속시 확인한다.
  useEffect(() => {
    const localCourses = getLocalStorageValue<OnCourses>('course');
    if (localCourses && course) {
      const availableList = course.filter((c) => {
        const foundCourse = Object.entries(localCourses).find(
          ([key, value]) => {
            return key === c.id && value === c.password;
          }
        );
        return foundCourse;
      });
      setLocalStorageValue(
        'course',
        availableList.length > 0
          ? Object.fromEntries(availableList.map((i) => [i.id, i.password]))
          : null
      );
      setOnCourseList(availableList.map((i) => i._id));
    } else {
      setOnCourseList(null);
    }
  }, [course]);

  return {
    isAuthenticated: !!authUser,
    course,
    onCourseList,
    loading,
    setLoading,
    setOnCourseList,
    signOut,
  };
};
