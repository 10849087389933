import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Preview from 'src/components/Preview/Preview';

/**
 * 영상이 제대로 들어갔는지 확인하기 위하여 만든 페이지다.
 */
const PreviewPage: FC = () => {
  const { lectureId } = useParams();
  return lectureId ? <Preview lectureId={lectureId} /> : null;
};

export default PreviewPage;
