import { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import MovieCard from 'src/components/MovieCard/MovieCard';
import useLectureById from 'src/hooks/useLectureById';
import usePreviewToken from 'src/hooks/usePreviewToken';
import { useAuth } from 'src/stores/auth-context';

import classes from './Preview.module.scss';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * 영상이 제대로 들어갔는지 확인하기 위하여 만든 페이지다.
 */
const Preview: FC<{ lectureId: string }> = ({ lectureId }) => {
  const query = useQuery();
  const { setLoading } = useAuth();
  const { isTokenValid, isTokenLoading } = usePreviewToken(
    query.get('token') ?? ''
  );
  const { lecture, lectureLoading } = useLectureById(
    isTokenValid ? lectureId ?? null : null
  );

  const isLoading = lectureLoading || isTokenLoading;

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  return (
    <div className={classes.previewContainer}>
      <p>※ 동영상이 문제없이 보이는지 확인하기 위한 페이지입니다.</p>
      {!isTokenValid && !isTokenLoading && (
        <p>
          만료된 페이지입니다.
          <br />
          보안을 위해 미리 보기 페이지는 임시 접속만 허용합니다.
          <br />
          다시 보려면 관리자 페이지의 미리보기 버튼으로 접속해주세요.
        </p>
      )}
      {lecture && (
        <>
          <h1>{lecture?.name ?? ''}</h1>
          <MovieCard embedUrl={lecture.embedUrl} />
          <div className={classes.lectureInfo}>
            {lecture.description && <b>{lecture.description}</b>}
            <p>
              본 강의는 누나영의 자산입니다. 무단 복제 및 배포시 저작권법에
              위반됨을 알려드립니다.(저작권법125조)
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Preview;
