import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { v4 } from 'uuid';

export const isDevelopment = process.env.NODE_ENV === 'development';

export const sessionId = v4();

export const sleep = async (ms?: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatNumber = (value: number) =>
  Intl.NumberFormat().format(value);

export const formatDateForKR = (date: string) => {
  return format(new Date(date), 'dd(EEEEEE)', { locale: ko });
};

/**
 * - 를 삽입한다.
 */
export function normalizeTel(telNo?: string) {
  if (telNo == null || telNo === '') {
    return '';
  }
  // 숫자 이외에는 모두 제외한다.
  telNo = telNo.replace(/[^0-9]/g, '');

  // 2018-11-15 부터는 050으로 변환해서 FS에 저장하기 때문에 불펼요할 수 있다.
  telNo = telNo.replace(/^090/, '050');

  // 010- , 070-
  let matches = telNo.match(/^(0[17][01])(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050은 4자리 식별번호를 사용하지만 3자리가 익숙하니 12자리가 아닌 경우에는 050에서 끊어준다.
  // 050-AAA?-BBBB
  matches = telNo.match(/^(050)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050X-AAAA-BBBB
  matches = telNo.match(/^(050.)(.{4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(02)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(0..)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  return telNo;
}

export const filterInt = (value: string) => {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value);
  } else {
    return NaN;
  }
};

export const getDeliveryDate = (
  days: number,
  orderDeliveryDate?: string,
  orderDate?: Date
) => {
  const now = orderDate ?? new Date();
  return (
    orderDeliveryDate ??
    format(
      new Date(now.setDate(now.getDate() + days)),
      "yyyy-MM-dd'T'08:00:00+0900"
    )
  );
};

const matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g;
export const escapeStringRegExp = (str: string) => {
  return new RegExp(str.replace(matchOperatorsRe, '\\$&'));
};

/**
 * 값이 undefined인 경우에 대한 처리를 추가한 JSON.parse
 */
export function jsonParseWithCatch<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? 'null');
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

const defaultTts = 1000 * 60 * 60 * 12;
export function setLocalStorageValue<T>(key: string, value: T, tts?: number) {
  const expire = Date.now() + (tts ?? defaultTts);
  const item = {
    value,
    expire,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageValue<T>(key: string) {
  const storedLocalItem = jsonParseWithCatch<{
    value: T;
    expire: string;
  }>(localStorage.getItem(key));
  if (Date.now() > Number(storedLocalItem?.expire ?? 0)) {
    localStorage.removeItem(key);
    return null;
  }
  return storedLocalItem?.value;
}

export async function getPublicIp() {
  try {
    const response = await fetch('https://api.ipify.org?format=json', {
      cache: 'no-cache',
    });
    if (response.ok) {
      const body = await response.json();
      return body.ip as string;
    } else {
      return 'unknown';
    }
  } catch (error) {
    console.error('[getPublicIp]', error);
    return 'error';
  }
}

export const getLastObject = (obj: any) => {
  const keys = Object.keys(obj);
  return obj[keys[keys.length - 1]];
};

export const textSort = (a: string, b: string) => {
  return a < b ? -1 : a > b ? 1 : 0;
};

/**
 * url에 query parameter를 추가한다.
 */
export const appendQueryParam = (url: string, key: string, value: string) => {
  const urlObject = new URL(url);
  urlObject.searchParams.set(key, value);
  return urlObject.toString();
};
