import { message } from 'antd';
import { FC, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import ClassroomPage from './pages/ClassroomPage';
import HomePage from './pages/HomePage';
import LecturePage from './pages/LecturePage';
import PreviewPage from './pages/PreviewPage';
import SigninPage from './pages/SigninPage';
import { useAuth } from './stores/auth-context';

const Router: FC = () => {
  const { loading, onCourseList } = useAuth();
  const isOnCourse = useMemo(() => {
    if (onCourseList && onCourseList?.length === 0) {
      message.info('계정정보가 변경되어 로그아웃합니다.');
    }
    return (onCourseList ?? [])?.length > 0;
  }, [onCourseList]);

  return (
    <>
      <Layout isLoading={loading}>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={isOnCourse ? '/home' : '/signin'} />}
          />
          {!isOnCourse && <Route path="/signin" element={<SigninPage />} />}
          {isOnCourse && (
            <>
              <Route path="/home" element={<HomePage />} />
              <Route
                path="/classroom/:classroomId"
                element={<ClassroomPage />}
              />
              <Route path="/lecture/:lectureId" element={<LecturePage />} />
            </>
          )}
          <Route path="/preview/:lectureId" element={<PreviewPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
    </>
  );
};

export default Router;
