import { RightOutlined } from '@ant-design/icons';
import { FC, useEffect, useState } from 'react';

import {
  subscribeClassroomById,
  subscribePlayLectureForClassrooom,
} from 'src/lib/3/firebase-short-cut';
import TopNavigationBar from '../TopNavigationBar/TopNavigationBar';
import { Avatar, List, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlayClassroomDoc, PlayLectureDoc } from 'src/lib/1/schema';

import classes from './Classroom.module.scss';

type ClassroomProps = {
  classroomId: string;
};

const Classroom: FC<ClassroomProps> = ({ classroomId }) => {
  const navigate = useNavigate();
  const [classroom, setClassroom] = useState<PlayClassroomDoc | null>(null);
  const [lectures, setLectures] = useState<PlayLectureDoc[] | null>(null);

  const goToLecture = (id: string) => {
    navigate(`/lecture/${id}`);
  };

  useEffect(() => {
    if (classroomId) {
      const classroomSubscription =
        subscribeClassroomById(classroomId).subscribe(setClassroom);
      const subscription =
        subscribePlayLectureForClassrooom(classroomId).subscribe(setLectures);

      return () => {
        subscription.unsubscribe();
        classroomSubscription.unsubscribe();
      };
    }
  }, [classroomId, setClassroom]);

  useEffect(() => {
    if (classroom && classroom.hide) {
      message.info('비공개된 강의실입니다.');
      navigate('/');
    }
  }, [classroom, navigate]);

  return (
    <div>
      <TopNavigationBar leftButton={true} title={classroom?.name ?? ''} />
      {lectures && (
        <List
          className={classes.lectureList}
          itemLayout="horizontal"
          dataSource={lectures}
          size="large"
          renderItem={(lecture) => {
            if (!lecture) {
              return null;
            }
            return (
              <List.Item onClick={() => goToLecture(lecture._id)}>
                <List.Item.Meta
                  className={classes.item}
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: '#00bfa5',
                        verticalAlign: 'middle',
                      }}
                      gap={10}
                    >
                      {lecture.number}
                    </Avatar>
                  }
                  title={<h3>{lecture.name}</h3>}
                  description={
                    <span className={classes.lectureDescription}>
                      {lecture.description ?? ''}
                    </span>
                  }
                />
                {!lecture.hide && <RightOutlined />}
              </List.Item>
            );
          }}
        />
      )}
    </div>
  );
};

export default Classroom;
