import { FC, PropsWithChildren } from 'react';
import Loading from './Loading';

type LayoutProps = {
  isLoading?: boolean;
};

const mainStyle = {
  margin: '0 auto',
  height: '100%',
  backgroundColor: 'white',
};

const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  children,
  isLoading,
}) => {
  return (
    <>
      <main style={mainStyle}>{children}</main>
      {isLoading && <Loading fontSize={56} />}
    </>
  );
};

export default Layout;
