import { useMemo } from 'react';
import { PlayCourseDoc } from 'src/lib/1/schema';
import { FirebaseManager } from 'src/lib/2/firebase-manager';
import useSWR from 'swr';

const firebaseManager = FirebaseManager.getInstance();
const playCoursePath = 'playCourse';

const getCourse = async (joinedIds: string) => {
  const ids = joinedIds.split('.');
  const promises = ids.map((id) => {
    return firebaseManager.getDoc<PlayCourseDoc>(`${playCoursePath}/${id}`);
  });
  return Promise.all(promises);
};

const useCourses = (
  ids: string[]
): {
  courses: PlayCourseDoc[] | null;
  coursesLoading: boolean;
} => {
  const joinedIds = ids.join('.');
  const memoizedIds = useMemo(
    () => (joinedIds.length > 0 ? joinedIds : null),
    [joinedIds]
  );

  const { data: courses, isValidating: coursesLoading } = useSWR(
    ids ? memoizedIds : null,
    getCourse
  );

  return {
    courses:
      (courses?.filter((course) => course !== undefined) as PlayCourseDoc[]) ??
      null,
    coursesLoading,
  };
};

export default useCourses;
