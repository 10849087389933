import { getLectureById } from 'src/lib/3/firebase-short-cut';
import useSWR from 'swr';

const useLectureById = (id: string | null) => {
  const { data: lecture, isValidating: lectureLoading } = useSWR(
    id ? id : null,
    getLectureById
  );

  return {
    lecture,
    lectureLoading,
  };
};

export default useLectureById;
