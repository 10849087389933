import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Classroom from 'src/components/Classroom/Classroom';

import { useTitle } from '../hooks/useTitle';

const ClassroomPage: FC = () => {
  const { classroomId } = useParams();
  useTitle('Plan NNY');

  return classroomId ? (
    <Classroom classroomId={classroomId} />
  ) : (
    <Navigate to="/" replace />
  );
};

export default ClassroomPage;
