import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Lecture from 'src/components/Lecture/Lecture';

import { useTitle } from '../hooks/useTitle';

const LecturePage: FC = () => {
  const { lectureId } = useParams();
  useTitle('Plan NNY');

  return lectureId ? (
    <Lecture lectureId={lectureId} />
  ) : (
    <Navigate to="/" replace />
  );
};

export default LecturePage;
