import { map } from 'rxjs';
import { PlayClassroomDoc, PlayCourseDoc, PlayLectureDoc } from '../1/schema';
import { FirebaseManager } from '../2/firebase-manager';
import { PreviewToken } from '../2/schema-config';

const firebaseManager = FirebaseManager.getInstance();
const playCoursePath = 'playCourse';
const playClassroomPath = 'playClassroom';
const playLecturePath = 'playLecture';

export const getClassroomById = (id: string) => {
  return firebaseManager.getDoc<PlayClassroomDoc>(`${playClassroomPath}/${id}`);
};

export const getLectureById = (id: string) => {
  return firebaseManager.getDoc<PlayLectureDoc>(`${playLecturePath}/${id}`);
};

export const subscribeClassroomById = (id: string) =>
  firebaseManager.observeDoc<PlayClassroomDoc>(`${playClassroomPath}/${id}`);

export const subscribeLectureById = (id: string) =>
  firebaseManager.observeDoc<PlayLectureDoc>(`${playLecturePath}/${id}`);

export const subscribePlayCourse = firebaseManager
  .observeCollection<PlayCourseDoc>(playCoursePath, [])
  .pipe(map((docs) => docs.filter((doc) => !doc._timeDelete)));

export const subscribePlayLectureForClassrooom = (id: string) =>
  firebaseManager
    .observeCollection<PlayLectureDoc>(
      playLecturePath,
      [['classroomId', '==', id]],
      {
        sortKey: 'number',
        orderBy: 'asc',
      }
    )
    .pipe(
      map((docs) =>
        docs
          .filter((doc) => !doc._timeDelete && !doc.hide)
          .sort((a, b) => a.number - b.number)
      )
    );

export const getPreviewToken = () =>
  firebaseManager.getDoc<PreviewToken>('previewToken/play-nny');
