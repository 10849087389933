import { message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlayLectureDoc } from 'src/lib/1/schema';
import { subscribeLectureById } from 'src/lib/3/firebase-short-cut';
import MovieCard from '../MovieCard/MovieCard';
import TopNavigationBar from '../TopNavigationBar/TopNavigationBar';

import classes from './Lecture.module.scss';

type LectureProps = {
  lectureId: string;
};

const Lecture: FC<LectureProps> = ({ lectureId }) => {
  const navigate = useNavigate();
  const [lecture, setLecture] = useState<PlayLectureDoc | null>(null);

  useEffect(() => {
    if (lectureId) {
      const subscription =
        subscribeLectureById(lectureId).subscribe(setLecture);

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [lectureId]);

  useEffect(() => {
    if (lecture?.hide) {
      message.info('비공개된 강의입니다.');
      navigate('/');
    }
  }, [lecture?.hide, navigate]);

  return (
    <div>
      <TopNavigationBar leftButton={true} title={lecture?.name ?? ''} />
      {lecture && (
        <div className={classes.lectureContainer}>
          <MovieCard embedUrl={lecture.embedUrl} />

          <div className={classes.lectureInfo}>
            {lecture.description && <b>{lecture.description}</b>}
            <p>
              본 강의는 누나영의 자산입니다. 무단 복제 및 배포시 저작권법에
              위반됨을 알려드립니다.(저작권법125조)
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lecture;
